import Vue from "vue";
import VueRouter from "vue-router";
import hasToken from "@/middleware/auth";
import Login from "@/views/login/LoginView.vue";
import Forgot from "@/views/login/ForgotView.vue";
import Success from "@/views/SuccessView.vue";
import SuccessOrder from "@/views/SuccessOrderView.vue";
import PassRewrite from "@/views/login/PassRewriteView.vue";
import CreateUser from "@/views/login/CreateUserView.vue";
import Register from "@/views/register/RegisterView.vue";
import AddAddress from "@/views/register/AddAddressView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Register",
    beforeEnter: hasToken,
    component: Register
  },
  {
    path: "/add-address",
    name: "AddAddress",
    beforeEnter: hasToken,
    component: AddAddress
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot
  },
  {
    path: "/success",
    name: "Success",
    component: Success
  },
  {
    path: "/success-order",
    name: "SuccessOrder",
    component: SuccessOrder
  },
  {
    path: "/pass-rewrite",
    name: "PassRewrite",
    component: PassRewrite
  },
  {
    path: "/create-user",
    name: "CreateUser",
    component: CreateUser
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
