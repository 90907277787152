<template>

  <div class="m-5 ps-md-4">

    <div>

      <p class="bold">A senha criada precisa ter no mínimo 8 dígitos e pelo menos um caracter especial, um número e uma letra maiúscula.</p>

      <section>

        <div>
          <label class="col-12 ms-1 mb-2">E-mail:</label>
          <input 
            class="input-24 col-10" 
            type="text" 
            v-model="email" 
            placeholder="E-mail">
        </div>

        <div class="align-items-center mt-2">
          <label class="col-auto ms-1 mb-2">Nova senha:</label>
          <input 
            class="input-24 col-10" 
            :type="showPass ? 'text': 'password'" 
            v-model="password" 
            placeholder="Nova Senha">
          <img 
            @click="showPass = !showPass" 
            class="ms-3 col-auto" 
            width="24" 
            height="24" 
            src="https://img.icons8.com/material-outlined/24/visible--v1.png"/>
        </div>

        <div class="align-items-center mt-2">
          <label class="col-12 ms-1 mb-2">Confirmação de senha:</label>
          <input 
            class="input-24 col-10" 
            :type="showConfirmPass ? 'text': 'password'" 
            v-model="cpassword" 
            placeholder="Confirmação de Senha">
          <img 
            @click="showConfirmPass = !showConfirmPass" 
            class="ms-3 col-auto" 
            width="24" 
            height="24" 
            src="https://img.icons8.com/material-outlined/24/visible--v1.png"/>
        </div>

        <div class="mt-2">
          <label class="col-10 ms-1 mb-2">Código recebido por E-mail:</label>
          <input 
            class="input-24 col-10" 
            type="text" 
            v-model="code" 
            placeholder="Código">
        </div>

      </section>

    </div>

    <Loading 
      class="mt-5 col-md-10"
      v-if="loading" 
      :withLogo="false" 
      :loading="loading"/>

    <div v-else class="footer col-12 d-flex mt-5 justify-content-around align-items-center">

      <button 
        class="button-24 bpink white w-25 bold p-2"
        @click="routerTo('Login')">
        Voltar
    </button>

      <button 
        class="button-24 bgreen white col-auto bold p-2 me-md-5"
        @click="resetPassword()">
        Alterar Senha
      </button>

    </div>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue"

export default {
  name: "CardRewrite",
  data() {
    return {
      email: null,
      password: null,
      cpassword: null,
      code: null,
      showPass: false,
      showConfirmPass: false,
      loading: false
    };
  },
  components: {
    Loading
  },
  methods: {

    ...mapActions('auth', 
      [
        'ActionResetPassword'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    async resetPassword() {
      this.loading = true
      await this.ActionResetPassword({
        email: this.email,
        password: this.password,
        token: this.code
      })
      .then(() => {
        this.$router.push({
          name: 'Login'
        })
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false
        this.resetState()
      })
    },

    resetState() {
      this.email = ''
      this.password = ''
      this.cpassword = ''
      this.code = ''
    }

  },
  created() { 
  },
};

</script>
  
<style scoped>

input {
  margin-left: 5px !important;
}

label::placeholder {
  font-size: 12px;
}

h5, h6 {
  display: inline-block;
  font-family: 'Raleway';
}

p {
  font-size: 14px;
  text-align: justify;
  max-width: 390px;
}

</style>