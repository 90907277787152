<template>

  <div>

    
    <div class="row align-items-center justify-content-center ms-md-4">
      
      <p class="info p-2 ps-3 pe-3 bold rounded-2">Agora só precisamos fazer mais algumas perguntas para podermos enviou o profissional mais adequado para o ser serviço</p>

      <div class="d-flex dropdown col-12 mb-3 justify-content-center">
        
        <button
          class="button-24 dropdown-button bold col-12 white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="true"
          style="background-color: #186e51;">
          {{ categorieChoice }}
        </button>
        <ul class="dropdown-menu col-12">
          <li
            v-for="(categorie, key, index) in categories"
            :key="`${key}-${index}`"
          >
            <a class="dropdown-item" @click="selectCategorie(categorie)">
              {{ categorie }}
            </a>
          </li>
        </ul>

      </div>
      
      <div 
        class="d-flex dropdown col-12 mb-3 justify-content-center"
        v-if="categorieChoice !== 'Selecione uma categoria'">
        <button
          class="button-24 dropdown-button bold col-12 white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="background-color: #186e51;">
          {{ serviceTypeChoice }}
        </button>
        <ul class="dropdown-menu">
          <li
            v-for="(serviceType, key, index) in servicesType"
            :key="`${key}-${index}`"
          >
            <a class="dropdown-item" @click="selectServiceType(serviceType)">
              {{ serviceType }}
            </a>
          </li>
        </ul>
      </div>

      <div 
        class="d-flex dropdown col-12 mb-3 justify-content-center"
        v-if="serviceTypeChoice !== 'Selecione um tipo de serviço'">
        <button
          class="button-24 dropdown-button bold col-12 white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="background-color: #186e51;">
          {{ serviceChoice }}
        </button>
        <ul class="dropdown-menu">
          <li
            v-for="(service, key, index) in services"
            :key="`${key}-${index}`"
          >
            <a class="dropdown-item" @click="selectService(service)">
              {{ service }}
            </a>
          </li>
        </ul>
      </div>

    </div>

    
    <section 
      class="col row ms-md-4"
      v-if="serviceChoice !== 'Escolha o serviço'"
      >

      <label class="col-12 mb-2">Descreva o serviço que você precisa:</label>
      <textarea
        class="col-12"
        name=""
        id=""
        cols="50"
        rows="4"
        placeholder=" Adicione aqui a descrição"
        v-model="description">
      </textarea>
      
      <div class="col-12 justify-content-end align-items-center">
  
        <label 
          for="image"
          class="button-24 label-image col-12 text-center mt-3">
            Adicione fotos se achar necessário
        </label>
        <input 
          type="file" 
          @change="uploadImage" 
          accept="image/*"
          id="image"
          name="image"
          class="col-12 d-none"/>
  
        <div 
          class="photos mt-1 mb-3 d-flex justify-content-start rounded-3">
  
          <div 
            class="col d-flex"
            v-if="previewsUrl.length"
            v-for="(previewUrl, index) in previewsUrl" :key="`${index}-previewUrl`">
  
            <img 
              :src="previewUrl" 
              class="col preview-image m-3 rounded-3"
              alt="Preview"/>
            <svg 
              xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50"
              class="col-auto mt-2"
              @click="removeItem(index)">
              <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path>
            </svg>
  
          </div>
  
        </div>
  
      </div>

      <div>

        <p 
          v-if="fullAddresses && fullAddresses.length"
          class="m-0 mt-4 green"
          style="
            font-size: 13px;
          ">
           Endereço selecionado: <strong>{{ selectedAddress }}</strong>
        </p>

        <section
          v-if="fullAddresses && fullAddresses.length > 1"  
        >
          <p 
          class="button-24 bolder mt-2 green"
          @click="(showAddresses = true)"
          style="
              cursor: pointer;
              font-size: 16px;
              text-decoration: underline;
              text-underline-offset: 4px;"
          >
              Escolha outro endereço cadastrado
          </p>
            
          <div 
            v-show="showAddresses"
            style="
              max-height: 150px;
              overflow-y: auto;"
          > 
            <p 
              v-for="(address, index) in fullAddresses"
              :key="`${index}-address`"
              class="list-address mb-1 mt-3"
              @click="updateAddress(address)"
              style="font-size: 14px;">
              {{ address.street + ', ' + address.number + ', ' + address.city ?? 's/n' + ', ' + address.city }}
            </p>
          </div>
      
          <hr class="mb-2">
          <h6 class="bolder mt-4 mb-4">ou</h6>
      
        </section>

        <button 
          class="button-24 col-12 bpink white bold"
          @click="routerTo('AddAddress')">
            Cadastre um novo endereço
        </button>

      </div>
  
      <!-- <div class="mt-4">
  
        <h5 class="raleway">Para quando você precisa do serviço?</h5>
  
      </div> -->

      <div class="mt-4">
      
        <div 
          class="row p-4 align-items-center justify-content-around rounded-3"
          @click="isUrgent"
          :style=" urgent ? 'border: 2px solid var(--yellow);' : 'border: 1px solid lightgray;'">
  
          <img 
            src="@/assets/register/urgent.png" 
            class="col-6"
            style="
              max-width: 170px;
            ">
          <div class="col-auto text-center">
            <h4 class="col-6 green raleway m-0">Agendar agora</h4>
            <p 
              class="col-6 bold"
              style="font-size: 13px;">
                Urgente
            </p>
          </div>
  
        </div>
  
        <!-- <div 
          class="row p-4 align-items-center justify-content-center rounded-3 mt-4"
          :style=" !urgent ? 'border: 2px solid var(--yellow);' : 'border: 1px solid lightgray;'"
          @click="schedule">
  
          <div class="col-5 text-center">
            <h4 class="col-6 green raleway m-0">Para depois</h4>
            <p 
              class="col-6 bold"
              style="
                font-size: 13px;
                color: var(--red);
              ">
                {{ dateChoice !== '' ? dateChoice : 'Agendar' }}
            </p>
          </div>
          <img 
            src="@/assets/register/after.png" 
            class="col"
            style="
              max-width: 140px;
            ">
  
        </div> -->
  
        <div 
          class="row justify-content-center mt-3"
          @click="selectDate()">
          
          <Datepicker 
            class="col-auto"
            v-model="dateChoice"
            v-show="showDatePicker"
            :format="'dd/MM/yyyy'"
            :language="ptBR"
            :inline="true"
            />
  
        </div>
  
        <div 
          v-if="!urgent && dateChoice !== ''"
          class="row ms-md-4 align-items-center">
  
          <h6 class="d-inline-block col-6">Horário para atendimento:</h6>
  
          <div 
            class="col-2"
            style="
              border: 4px solid var(--yellow);
              border-left: 0;
              border-right: 0;
              max-height: 60px; 
              overflow-y: auto;
            ">
            <span
              class="span-options d-block text-center green bold" 
              v-for="(hour, index) in hours"
              :key="`${index}-hour`"
              @click="chour = hour"
              >
              {{ hour }}
            </span>
          </div>
  
          <div 
            class="col-2 m-0 ms-1"
            style="
              border: 4px solid var(--green);
              border-left: 0;
              border-right: 0;
              max-height: 60px; 
              overflow-y: auto;
            ">
            <span
              class="span-options d-block text-center green bold" 
              v-for="(minute, index) in minutes"
              :key="`${index}-minute`"
              @click="cminute = minute"
              >
              {{ minute }}
            </span>
          </div>
  
          <div class="d-flex">
            <h3 class="d-inline-block col-auto p-0" style="font-family: 'Raleway';">{{ chour }}</h3>
            <h3 class="d-inline-block col-auto p-0" style="font-family: 'Raleway';">h</h3>
            <h3 class="d-inline-block col-auto p-0" style="font-family: 'Raleway';">{{ cminute }}</h3>
          </div>
  
        </div>
  
      </div>

      <Loading 
        v-if="loading" 
        :loading="loading"
        :withLogo="false" 
        class="col-12 mt-5 pb-3"/> 
      <div v-else class="row mb-3 mt-5">
        <button 
          class="col btn red bold"
          @click="resetState()"
        >
          Cancelar chamado</button>
        <button 
          class="col button-24 green bolder"
          @click="createOrder()"
          style="
            border: 1px solid var(--green) !important;
            background-color: white;
          "
        >
          Buscar profissional
        </button>
      </div>
      
    </section>

  </div>

</template>

<script>

import Datepicker from 'vuejs-datepicker';
import { mask } from 'vue-the-mask'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue"

export default {
  name: "CardRegister",
  data() {
    return {
      categories: [],
      categorie: null,
      categorieChoice: "Selecione uma categoria",
      servicesType: [],
      serviceType: null,
      serviceTypeChoice: "Selecione um tipo de serviço",
      services: [],
      service: null,
      serviceChoice: "Escolha o serviço",
      servicesArray: [],
      serviceInfo: {},
      description: null,
      userId: null,
      previewsUrl: [],
      addressText: null,
      showAddresses: false,
      showDatePicker: false,
      dateChoice: '',
      ptBR: ptBR,
      urgent: true,
      chour: null,
      cminute: null,
      hours:  Array.from({ length: 24 }, (_, i) => this.zfill(i.toString(), 2)), 
      minutes: Array.from({ length: 60 }, (_, i) => this.zfill(i.toString(), 2)),
      imageUrl: null,
      serviceId: null,
      addressId: null,
      loading: false
    };
  },
  components: {
    Datepicker,
    Loading
  },  
  directives: {
    mask
  },
  props: {
    fullCategories: Array,
    fullAddresses: Array,
    selectedAddress: String
  },  
  methods: {

    ...mapActions('register', 
      [
        'ActionAddOrder',
        'UploadToFirebase'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    async uploadImage(event) {

      const file = event.target.files[0];

      if (file) {

        try {

          const downloadLink = await this.UploadToFirebase( { fb: this.$firebase, file } )
          this.previewsUrl.unshift(downloadLink)
          localStorage.setItem('previewsUrl', JSON.stringify(this.previewsUrl))

        } catch (error) {
          console.log('Erro ao fazer upload da imagem:', error)
        }

      }
    },

    removeItem(index) {
      this.previewsUrl.splice(index, 1);
      localStorage.setItem('previewsUrl', JSON.stringify(this.previewsUrl))
    },
    
    selectCategorie(categorie) {

      this.categorieChoice = categorie
      localStorage.setItem('categorieChoice', JSON.stringify(categorie))

      this.servicesType = this.updateServicesType()

      this.serviceTypeChoice = "Selecione um tipo de serviço" 
      localStorage.setItem('serviceTypeChoice', JSON.stringify(this.serviceTypeChoice))
      this.serviceChoice = "Escolha o serviço",
      localStorage.setItem('serviceChoice', JSON.stringify(this.serviceChoice))
      this.servicesInfo = []
      
    },
    
    updateServicesType() {

      
      const servicesType = this.fullCategories.filter(categorie => { 
        return categorie.label === this.categorieChoice
      })

      if (!servicesType.length) return []

      return servicesType[0].subCategories.map(subCategorie => {
        return subCategorie.label
      })

    },

    selectServiceType(serviceType) {

      this.serviceTypeChoice = serviceType
      localStorage.setItem('serviceTypeChoice', JSON.stringify(serviceType))

      this.services = this.updateServices()
      this.serviceChoice = "Escolha o serviço" 

    },

    updateServices() {

      return this.fullCategories.filter(categorie => { 
        return categorie.label === this.categorieChoice
      })[0].subCategories.filter(services => {
        return services.label === this.serviceTypeChoice
      })[0].services.map(service => {
        this.servicesArray.push(service)
        return service.label
      })

    },

    selectService(service) {

      this.serviceChoice = service
      localStorage.setItem('serviceChoice', JSON.stringify(service))

      const serviceInfoSearch = this.servicesArray.filter(serviceInfo => {
        return serviceInfo.label === service
      })
      
      if (!serviceInfoSearch.length) return
      
      this.serviceId = serviceInfoSearch[0].id
      localStorage.setItem('serviceId', JSON.stringify(this.serviceId))

      this.serviceInfo = serviceInfoSearch[0]

    },

    updateAddress(address) {
      this.addressId = address.id
      localStorage.setItem('addressId', JSON.stringify(this.addressId))
      this.$emit('updateAddress', address);
      this.showAddresses = false
    },

    isUrgent() {
      this.urgent = true
      this.showDatePicker = false
      this.showHour = false
    },

    selectDate() {
      this.showDatePicker = false
      this.dateChoice = new Date(this.dateChoice).toLocaleDateString()
      this.showHour = true
    },

    schedule() {
      this.showDatePicker = true
      this.urgent = false
    },

    setHours() {
      const hour = String(new Date().getHours())
      const minute = String(new Date().getMinutes())
      this.chour = this.zfill(hour, 2)
      this.cminute = this.zfill(minute, 2)
    },

    zfill(str, width) {
      const numZerosToAdd = width - str.length;
      if (numZerosToAdd > 0) {
        return "0".repeat(numZerosToAdd) + str;
      }
      return str;
    },

    resetState() {

      this.categorieChoice = "Selecione uma categoria"
      this.serviceTypeChoice = "Selecione um tipo de serviço"
      this.serviceChoice = "Escolha o serviço"
      this.previewsUrl = []
      this.description = null

      localStorage.clear()

    },

    formatDate() {
      const dateSplitted = this.dateChoice.split('/')
      return dateSplitted[2] + '-' 
        + dateSplitted[1] + '-' 
        + dateSplitted[0] + ' ' 
        + this.chour + ':' 
        + this.cminute + ':00'
    },

    async createOrder() {

      this.loading = true

      try {

        const datetime = this.urgent ? 
          null : 
          this.formatDate()
  
        const payload = {
          order: {
            datetime,
            description: this.description,
            orderType: this.urgent ? 'urgent' : 'scheduled',
            addressId: this.addressId,
            serviceId: this.serviceId,
            stage: 'searching_providers',
            status: 'searching_providers',
            budgetItems: [],
            photos: this.previewsUrl
          }
        }
  
        await this.ActionAddOrder(payload)
        this.resetState()
        this.$router.push({
          name: 'SuccessOrder'
        })

      } catch {} finally {
        this.loading = false
      }

    }

  },

  async created() {

    this.fullCategories.map(categorie => {
      this.categories.push(categorie.label)
    })

    this.setHours()

    if(this.fullAddresses.length) {
      this.addressId = this.fullAddresses[0].id
    }

    const keys = Object.keys(localStorage);
    
    keys.map(key => {
      if (this.$data.hasOwnProperty(key)) {
        this[key] = JSON.parse(localStorage[key])
      }
    })

    const address = JSON.parse(localStorage.getItem('address'))
    if (address) this.updateAddress(address)
    if (this.servicesType.length) this.servicesType = this.updateServicesType()
    if (this.services.length) this.services = this.updateServices()

  }
};

</script>
  
<style>

.span-options {
  cursor: pointer !important;
}

.span-options:hover {
  background-color: rgb(244, 247, 243);
}

.day-header {
  font-family: 'Raleway';
  background-color: var(--pink);
  color: White;
}

.day-header:hover {
  background-color: var(--pink) !important;
}

.selected {
  background-color: var(--green) !important;
  font-family: 'Raleway';
  color: white !important;
}

.day:hover {
  border: 1px solid var(--green) !important;
}

.list-address:hover {
  background-color: #e4fcf3;
  cursor: pointer;
}

li {
  cursor: pointer
}

ul {
  max-height: 200px;
  overflow-y: auto;
  max-width: 23em !important;
  min-width: 23em !important;
  border: 1px solid var(--green) !important;
}

a:active {
  background-color: var(--green) !important;
}

.label-image {
  background-color: var(--pink);
  color: rgb(255, 255, 255);
  font-weight: bolder;
  text-align: center;
}

.photos {
  background-color: rgb(255, 244, 244);
  max-width: 100% !important;
  max-height: 85px;
  overflow: auto;
}

.preview-image {
  max-width: 70px;
}

textarea {
  outline: none;
  box-shadow: 0 0 0 0 !important;
  padding-left: 10px;
  padding-top: 10px;
}

.info {
  font-size: 14px;
  text-align: justify;
  border: 1px solid var(--green);
  padding: 5px;
}

@media (min-width: 768px) {

  .photos {
    background-color: rgb(255, 244, 244);
    overflow: auto;
  }
  
  ul {
    max-width: 28.5em !important;
    min-width: 28.5em !important;
  }

}

</style>
