<template>
  
  <div class="">

    <div class="bg-up-forg">

      <img 
        class="pass-logo" 
        src="@/assets/login/white-logo.png">
      <img 
        class="pass-shape" 
        src="@/assets/login/pass-shape.png">

      <h2 class="pass-text bold col-7">Esqueceu sua senha?</h2>

    </div>

    <CardForgot id="full-screen"/>

  </div>

</template>

<script>

import CardForgot from '@/components/auth/CardForgot.vue';

export default {

  name: "ForgotView",

  components: {
    CardForgot  
  },

  created () {
    sessionStorage.clear()
  }

};

</script>

<style scoped>

.bg-up-forg {
  background-color: var(--red);
  min-height: 42vh;
}

.pass-logo {
  position: absolute;
  top: 35px;
  width: 90px;
  left: 20px;
}

.pass-shape {
  position: absolute;
  top: 0;
  width: 220px;
  right: 0;
}

.pass-text {
  position: relative;
  top: 30vh;
  left: 35px;
  font-size: 35px;
  color: white;
  font-family: 'Raleway';
}

@media (max-width: 400px) {

  .pass-shape {
    width: 180px;
  }

}

@media (min-width: 768px) {

  .bg-up-forg {
    min-height: 30vh;
  }

  .pass-text {
    top: 16vh !important;
  }

  .pass-logo {
    left: 40px;
    width: 9vw;
  }

  .pass-shape {
    width: 30vw;
  }

  .pass-text {
    top: 30vh;
    font-size: 50px !important;
    left: 20vw
  }

  #full-screen {
    margin-left: 20vw !important;
    width: 450px;
    padding: 40px 0 40px 0;
    border-left: 3px solid;
    max-height: 350px !important;
  }

}


</style>
