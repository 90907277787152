import { initializeApp } from 'firebase/app'

export const firebaseApp = initializeApp({

    apiKey: "AIzaSyB031L3tZs87MIXSNf6GDi-sov8vhhBUQQ",
    authDomain: "steadfast-bebop-338802.firebaseapp.com",
    projectId: "steadfast-bebop-338802",
    storageBucket: "steadfast-bebop-338802.appspot.com",
    messagingSenderId: "62196373264",
    appId: "1:62196373264:web:0731bade8d5f288462f795",
    measurementId: "G-DE1WWECSFY"
    
    // apiKey: process.env.API_KEY,
    // authDomain: process.env.AUTH_DOMAIN,
    // projectId: process.env.PROJECT_ID,
    // storageBucket: process.env.STORAGE_BUCKET,
    // messagingSenderId: process.env.MESSAGING_SENDER,
    // appId: process.env.APP_ID

})

export default function install (Vue) {
    Object.defineProperty(Vue.prototype, '$firebase', {
        get () {
            return firebaseApp
        }
    })
}