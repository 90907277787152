import Vue from 'vue';

const success = (message = '', title = '', time = 1000) => {
    Vue.prototype.flashMessage.success({
        title,
        message,
        time,
        status: 'success'
    });
};

const error = (message = '', title = '', time = 1000) => {
    Vue.prototype.flashMessage.error({
        title,
        message,
        time,
        status: 'error'
    });
}

const warning = (message = '', title = '', time = 1000) => {
    Vue.prototype.flashMessage.warning({
        title,
        message,
        time,
        status: 'warning'
    });
}


export default {
    success,
    error,
    warning
}
