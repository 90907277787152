<template>
  <div id="app">
    <router-view />
    <FlashMessage :position="'right top'"></FlashMessage>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {},
  };
</script>

<style>
</style>
