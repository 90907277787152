<template>
  
  <div>
    
    <div class="bg-up-rewrite">

      <img 
        class="rewrite-logo" 
        src="@/assets/login/white-logo.png">
      <img 
        class="rewrite-shape" 
        src="@/assets/login/pass-shape.png">
      
      <h2 class="rewrite-text col-7">Esqueceu sua senha?</h2>
      
    </div>

    <CardRewrite id="full-screen"/>

  </div>

</template>

<script>

import CardRewrite from '@/components/auth/CardRewrite.vue'

export default {

  name: "PassRewriteView",

  components: {
    CardRewrite
  },

  created () {
    sessionStorage.clear()
  }

};

</script>

<style scoped>

.bg-up-rewrite {
  background-color: var(--red);
  min-height: 42vh;
}

.rewrite-logo {
  position: absolute;
  top: 35px;
  width: 90px;
  left: 20px;
}

.rewrite-shape {
  position: absolute;
  top: 0;
  width: 220px;
  right: 0;
}

.rewrite-text {
  position: relative;
  top: 30vh;
  left: 35px;
  font-size: 35px;
  color: white;
  font-family: 'Raleway';
}

@media (min-width: 768px) {

  .bg-up-rewrite {
    min-height: 30vh;
  }

  .rewrite-text {
    top: 16vh !important;
  }

  .rewrite-logo {
    left: 40px;
    width: 9vw;
  }

  .rewrite-shape {
    width: 30vw;
  }

  .rewrite-text {
    top: 30vh;
    font-size: 50px !important;
    left: 20vw !important;
  }

  #full-screen {
    margin-left: 20vw !important;
    width: 450px;
    padding: 40px 0 40px 0;
    border-left: 3px solid;
    max-height: 500px !important;
  }

}

</style>
