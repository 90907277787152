export default async function hasToken(to, from, next) {
    
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

    if (!sessionStorage.getItem('token')) {
        if (from.path === '/login') return
        next(loginQuery)
    }

    else next()

}
