<template>

  <div class="m-5 ps-md-4 pb-md-2">
        
    <p class="mb-2 mt-md-0 bold">Nos informe abaixo seus dados para cadastro.</p>
    
    <form 
      class="mt-0" 
      @submit.prevent="createUser">
      
      <label class="col-12 mt-3">CPF ou CNPJ:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        placeholder="CPF ou CNPJ" 
        v-mask="['###.###.###-##', '##.###.###/####-##']" 
        v-model="document" 
        required>
  
      <label class="col-12 mt-3">Nome completo:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        placeholder="Nome completo" 
        v-model="name" 
        required>
  
      <label class="col-12 mt-3">E-mail:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        placeholder="E-mail" 
        v-model="email" 
        required>
      
      <div class="inline">
        <label class="col-12 mt-3">Telefone/Celular:</label>
        <input 
          class="input-24 col-12 mt-1" 
          type="text" 
          placeholder="DDD + Número" 
          v-mask="['(##) #####-####']" 
          v-model="phone" 
          required>
    
        <label class="col-12 mt-3">Data de Nascimento:</label>
        <input 
          class="input-24 col-12 mt-1" 
          type="text" 
          placeholder="DD/MM/AAAA" 
          v-mask="['##/##/####']" 
          v-model="birthday" 
          required>
      </div>
  
      <p class="mt-4 bold">
        A senha que será criada abaixo precisa ter no mínimo 8 dígitos e pelo menos um caracter especial, 
        um número e uma letra maiúscula.
      </p>

      <label class="col-12">Senha:</label>
      <div class="align-items-center mt-1">

        <input 
          class="input-24 col-12" 
          :type="showPass ? 'text': 'password'" 
          placeholder="Senha" 
          v-model="password" 
          required
        >

      </div>
      
      <label class="col-12 mt-3">Confirmar Senha:</label>
      <div class="align-items-center mt-1">

        <input 
          class="input-24 col-12" 
          :type="showConfirmPass ? 'text': 'password'" 
          placeholder="Confirmar Senha" 
          v-model="cpassword"
          required>
          
      </div>
  
      <label class="col-12 mt-3">Código de indicação:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        placeholder="Código" 
        v-model="indication">
      
      <Loading 
        class="mt-5 mb-3"
        v-if="loading" 
        :withLogo="false" 
        :loading="loading"/>

      <div v-else class="d-flex col-12 justify-content-around mt-5 pb-4">

        <button 
          @click="routerTo('Login')" 
          class="button-24 bpink white bolder col-5 p-2">
            Voltar
        </button>

        <button 
          type="submit" 
          class="button-24 bgreen white bold col-5 p-2 me-md-4">
            Criar usuário
        </button>

      </div>

    </form>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import { mask } from 'vue-the-mask'
import Loading from "@/components/Loading.vue"

export default {
  name: "CardCreateUser",
  data() {
    return {
      document: null,
      birthday: null,
      name: null,
      phone: null,
      email: null,
      password: null,
      cpassword: null,
      indication: null,
      showPass: false,
      showConfirmPass: false,
      loading: false
    };
  },
  components: {
    Loading
  },
  directives: {
    mask
  },
  methods: {

    ...mapActions('auth', 
      [
        'ActionCreateUser'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    async createUser() {

      localStorage.clear()
      
      this.loading = true

      const parsedCreateUser = this.parseToCreateUser()
      
      await this.ActionCreateUser(parsedCreateUser)
      .then(() => {
        this.$router.push({
          name: 'Register'
        })
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false
      })

    },

    parseToCreateUser() {

      const birthdayplitted = this.birthday?.split('/')
      const birthday = birthdayplitted[2] + '-' + birthdayplitted[1] + '-' + birthdayplitted[0]

      return {
        client: {
          name: this.name.trim(),
          status: 'active',
          birthday,
          cellphone_number: this.phone.replace(/\D/g, ''),
          client_type: 'person',
          document_number: this.document.replace(/[^\d]/g, ''),
          email: this.email.trim(),
          password_hash: this.password,
          password_confirmation: this.cpassword,
          device_id: '',
          indication_code: this.indication ?? ''
        }
      }

    },

    resetState() {
      this.document= null
      this.birthday= null
      this.name= null
      this.phone= null
      this.email= null
      this.password= null
      this.cpassword= null
      this.indication= null
    }

  },
  async created() {}
};

</script>
  
<style scoped>

h5, h6 {
  display: inline-block;
  font-family: 'Raleway';
}

p {
  font-size: 14px;
  text-align: justify;
}
/* @import "@/components/auth/style.css"; */
</style>