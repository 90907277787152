<template>

  <div class="m-5 ps-md-4">

    <section>

      <h5>Qual o seu</h5>&nbsp;
      <h5 class="red">e-mail</h5>&nbsp;
      <h5>cadastrado?</h5>
      <p>Você receberá um link de redefinição através do e-mail e poderá então realizar a alteração de senha</p>

    </section>

    <section>

      <input 
        class="input-24 col-12 mt-5" 
        type="text" 
        v-model="email" 
        placeholder=" E-mail">
      <p
        @click="routerTo('PassRewrite')" 
        class="green bold mt-4"
        style="cursor: pointer;">
          Já tem o código para realizar a alteração?
      </p>

    </section>

    <Loading 
      :withLogo="false" 
      class="mt-4 col-12 col-md-10"
      v-if="loading" 
      :loading="loading"/>

    <div v-else class="footer col-12 row justify-content-end">

      <button 
        class="button-24 byellow white bolder col-4 p-2 mt-3 me-md-5"
        @click="sendCodeEmail()">
        Enviar link
      </button> 

    </div>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue"

export default {
  name: "CardForgot",
  data() {
    return {
      email: null,
      loading: false
    };
  },
  components: {
    Loading
  },
  methods: {

    ...mapActions('auth', 
      [
        'ActionSendCodeEmail'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    async sendCodeEmail() {

      this.loading = true

      await this.ActionSendCodeEmail({
        email: this.email
      })
      .then(() => {
        this.$router.push({
          name: 'Success'
        })
      })
      .catch(() => {})
      .finally(() => {
        this.email = ""
        this.loading = false
      })

    }

  }
  
};

</script>
  
<style scoped>

h5, h6 {
  display: inline-block;
  font-family: 'Raleway';
}

p {
  font-size: 14px;
  text-align: justify;
  max-width: 390px;
}
/* @import "@/components/auth/style.css"; */
</style>