import { http } from '@/http';
import flashMessages from '@/utils/flash-messages';
import { ref as storageRef, getStorage, uploadBytesResumable, getDownloadURL  } from 'firebase/storage'
import fromTo from '../../../utils/fromTo'

export const ActionGetCategories = async ( _ ) => {

    try {
        const response = await http.request({
          url: '/categories',
          method: 'GET',
          json: true
        });

        return response.categories;

    } catch (error) {
        throw error;
    }

}

export const ActionAddAddress = async ( _, data ) => {

    const userId = sessionStorage.getItem('userId')

    try {
        const response = await http.request({
          url: `/clients/${userId}/addresses`,
          method: 'POST',
          data,
          json: true
        });

        flashMessages.success('Endereço cadastrado com sucesso.', '', 3000)
        localStorage.setItem('address', JSON.stringify(response.data))
        return response;

    } catch (error) {
        flashMessages.warning('Erro ao cadastrar endereço. Tente novamente.', '', 3000)
        throw error;
    }

}

export const ActionGetAddresses = async ( _ ) => {

    const userId = sessionStorage.getItem('userId')

    try {
        const response = await http.request({
          url: `/clients/${userId}/addresses`,
          method: 'GET',
          json: true
        });

        return response;

    } catch (error) {
        throw error;
    }

}

export const ActionAddOrder = async ( _, data ) => {

    try {
        const response = await http.request({
          url: `/clients/orders`,
          method: 'POST',
          data,
          json: true
        });

        return response;

    } catch (error) {
        flashMessages.warning(
          fromTo[ error.response?.data ] ??
          'Erro na solicitação do serviço. Tente novamente.',
          '',
          3000
        )
        throw error;
    }

}

export const UploadToFirebase = async ( _, uploadInfo ) => {

    const { fb, file } = uploadInfo
    const storage = getStorage(fb)
    const stRef = storageRef(storage, 'images_web/' + file.name)
    const upload = uploadBytesResumable(stRef, file)

    return new Promise((resolve, reject) => {
        upload.on('state_changed',
            (snapshot) => {},
            (error) => { throw error },
            () => {

                getDownloadURL(upload.snapshot.ref).then(downloadURL => {
                    resolve(downloadURL)
                }).catch(error => {
                    reject(error)
                });

            }
        )
    })

}
