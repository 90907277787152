<template>
  
  <div>
    
    <div class="bg-up-success">

      <img 
        class="success-logo" 
        src="@/assets/login/white-logo.png">
      <img 
        class="success-shape" 
        src="@/assets/login/mail-sent-shape.png">

      <div class="login-text-success">

        <h2 class="col-8">Dê uma olhada em sua caixa de entrada!</h2>
        <p @click="routerTo('Forgot')">Não recebeu o e-mail? Reenviar link</p>

      </div>

    </div>

    <button @click="routerTo('Login')">
      Voltar para o Login
    </button>

  </div>

</template>

<script>

import Loading from "@/components/Loading.vue"

export default {
  name: "SuccessView",
  components: {
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {

    routerTo(path) {
      this.$router.push({
        name: path
      });
    }

  },
  created() {
  }
};
</script>

<style scoped>

.bg-up-success {
  background-color: var(--pink);
  height: 100vh;
}

.success-logo {
  position: absolute;
  top: 35px;
  width: 90px;
  left: 20px;
}

.success-shape {
  height: 100vh;
  width: 100vw;
}

.login-text-success {
  position: absolute;
  top: 40vh;
  left: 35px;
  font-family: 'Raleway';
  color: white;
}

button {
  position: absolute;
  bottom: 40px;
  right: 40px;
  background-color: var(--green);
  border: 0;
  color: white;
  padding: 20px;
  font-weight: bolder;
}

</style>
