<template>
    <div v-if="loading" class="text-center">
      <img 
        v-if="withLogo"
        src="@/assets/login/logo-green.png"
        style="max-width: 100px; display: block;">
      <img src="@/assets/loading.gif" alt="">
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Loading",
    data() {
      return {
      };
    },
    props: {
      loading: Boolean,
      withLogo: Boolean
    },
    methods: {
    },
    created() { 
    },
  };
  
  </script>
    
  <style>
  </style>