<template>

  <div class="mb-4 mt-5">

    <div class="m-5 ms-md-5">
      <h2>Novo endereço</h2>
      <p class="bold" >Adicione um novo endereço em sua lista de endereços cadastrada</p>
    </div>
        
    <form @submit.prevent="addAddress" class="m-5 ms-md-5">

      <label class="col-12 mt-3">Rua:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text"
        id="street"
        v-model="street"
        required>

      <section class="row">

        <div class="col-3">
          <label class="col-12 mt-3">Número:</label>
          <input 
            class="input-24 col-12 mt-1" 
            type="text" 
            id="number" 
            name="number"
            v-model="number" 
            required>
        </div>
        
        <div class="col">
          <label class="col-12 mt-3">Complemento:</label>
          <input 
            class="input-24 col-12 mt-1" 
            type="text" 
            id="complement" 
            name="complement" 
            v-model="complement">
        </div>

      </section>
  
      <label class="col-12 mt-3">Ponto de referência:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        id="reference" 
        name="reference" 
        v-model="reference">
  
      <label class="col-12 mt-3">Bairro:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        id="neighborhood" 
        name="neighborhood" 
        v-model="neighborhood" 
        required>

      <section class="row">

        <div class="col">
          <label class="col-12 mt-3">Cidade:</label>
          <input 
            class="input-24 col-12 mt-1" 
            type="text" 
            id="city" 
            name="city" 
            v-model="city" 
            required>
        </div>

        <div class="col-3">
          <label class="col-12 mt-3">UF:</label>
          <input 
            class="input-24 col-12 mt-1" 
            type="text" 
            id="state" 
            name="state" 
            placeholder="" 
            v-model="state" 
            required>
        </div>

      </section>

      <label class="col-12 mt-3">Salvar endereço como:</label>
      <input 
        class="input-24 col-12 mt-1" 
        type="text" 
        id="addressLike" 
        name="addressLike" 
        placeholder="" 
        v-model="addressLike" 
        required>
  
      <Loading 
        v-if="loading" 
        :loading="loading"
        :withLogo="false" 
        class="col-10 mt-5 pb-3"/>

      <div v-else class="row justify-content-around mt-5">
        <button 
          @click="routerTo('Register')"
          class="button-24 bred col-4 p-2">
            Voltar
        </button>
        <button 
          type="submit" 
          class="button-24 col-5 me-3">
            Salvar endereço
        </button>
      </div>

    </form>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import { mask } from 'vue-the-mask'
import Loading from "@/components/Loading.vue"

export default {
  name: "CardCreateUser",
  data() {
    return {
      document: null,
      birthday: null,
      name: null,
      phone: null,
      email: null,
      password: null,
      cpassword: null,
      indication: null,
      showPass: false,
      showConfirmPass: false,
      loading: false,
      neighborhood: null,
      reference: null,
      city: null,
      addressLike: null,
      street: null,
      number: null,
      complement: null,
      state: null,
      lat: "0",
      lng: "0"
    };
  },
  components: {
    Loading
  },
  props: {
    userId: String
  },
  directives: {
    mask
  },
  methods: {

    ...mapActions('register', 
      [
        'ActionAddAddress'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    buildAddress() {
      return `${this.street}, ${this.city}, ${this.state}, ${this.country}`
    },

    async addAddress() {

      this.loading = true

      const geocoder = new google.maps.Geocoder();
      const address = this.buildAddress()

      await geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          const latitude = location.lat();
          const longitude = location.lng();
          this.lat = latitude
          this.lng = longitude
        } else {
          console.error("Geocode falhou devido a: " + status);
        }
      });

      const parsedCreateAddress = this.parseToAddAddress()

      await this.ActionAddAddress(parsedCreateAddress)
      .then(() => {
        this.$router.push({
          name: 'Register'
        })
      })
      .catch((err) => {console.log({err})})
      .finally(() => {
        this.loading = false
        this.resetState()
      })

    },

    parseToAddAddress() {

      return {
        address: {
          city: this.city.trim(),
          complement: this.complement?.trim(),
          latitude: String(this.lat),
          longitude: String(this.lng),
          neighborhood: this.neighborhood.trim(),
          number: this.number?.replace('s/n', '').trim(),
          reference_point: this.reference?.trim(),
          state: this.state,
          street: this.street.trim(),
          tag: this.addressLike
        }
      }

    },

    resetState() {
      this.document= null
      this.birthday= null
      this.name= null
      this.phone= null
      this.email= null
      this.password= null
      this.cpassword= null
      this.indication= null
    }

  },
  mounted() {

    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('street'),
      {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(-25.4284, -49.2733) // sede
        )
      }
    )

    google.maps.event.addListener(autocomplete, "place_changed", () => {

      const arrayInfo = autocomplete.getPlace().address_components

      arrayInfo.map((info) => {
        if (info.types.includes('street_number')) this.number = info.short_name
        else if (info.types.includes('route')) this.street = info.short_name
        else if (info.types.includes('administrative_area_level_2')) this.city = info.short_name
        else if (info.types.includes('administrative_area_level_1')) this.state = info.short_name
        else if (info.types.includes('sublocality_level_1')) this.neighborhood = info.short_name
      })

    })
    
  }
};

</script>
  
<style scoped>

button {
  background-color: var(--green);
  font-family: 'Raleway';
  margin-left: 20px;
  color: white;
}

/* @import "@/components/auth/style.css"; */
</style>
