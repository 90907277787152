<template>
  
  <div>

    <div class="bg-up-login">

      <img 
        class="login-logo" 
        src="@/assets/login/logo-green.png">
      <img 
        class="login-shape" 
        src="@/assets/login/login-shape.png">

      <div class="login-text">
        <p class="mb-0 bold">Antes de continuar o seu chamado</p>
        <h2 class="raleway">Faça o seu login</h2>
      </div>

    </div>
  
    <CardLogin id="full-screen"/>

  </div>

</template> 

<script>

import CardLogin from '@/components/auth/CardLogin.vue';

export default {

  name: "LoginView",

  components: {
    CardLogin
  },

  created () {
    sessionStorage.clear()
  },

};

</script>

<style scoped>

.bg-up-login {
  background-color: var(--yellow);
  min-height: 42vh;
}

.login-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
}

.login-shape {
  position: absolute;
  top: 0;
  width: 280px;
  right: 0;
}

.login-text {
  position: absolute;
  top: 32vh;
  left: 35px;
}

p {
  font-size: 13px;
}

@media (max-width: 400px) {

  .login-shape {
    width: 200px;
  }

  .login-text {
    width: 320px;
  }

}

@media (min-width: 768px) {

  .bg-up-login {
    min-height: 30vh;
  }

  .login-logo {
    left: 40px;
    width: 10vw;
  }

  .login-text {
    top: 16vh;
    left: 22vw;
  }

  .login-shape {
    width: 30vw;
  }

  p {
    font-size: 18px;
  }

  h2 {
    font-size: 50px !important;
  }

  #full-screen {
    margin-left: 20vw !important;
    width: 500px;
    padding: 40px 0 40px 0;
    border-left: 3px solid;
    max-height: 500px !important;
  }

}

</style>
