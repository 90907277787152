<template>

  <div class="m-5 mt-5 ps-md-4">

    <section>

      <h5>Qual o seu</h5>&nbsp;
      <h5 class="red">e-mail</h5>&nbsp;
      <h5>e senha?</h5>
      <p>Seu nome de usuário é criado no momento de cadastro e é sua forma de identificação no app</p>

    </section>

    <section class="">

      <input 
        class="input-24 col-11 mt-3 col-md-10" 
        type="text" 
        v-model="email" 
        placeholder=" E-mail">

      <div class="align-items-center mt-5 ms-md-0">
        <input 
          class="input-24 col-11 ms-md-0 col-md-10" 
          :type="showPass ? 'text': 'password'" 
          v-model="password" 
          placeholder=" Senha">
        <img 
          @click="showPass = !showPass" 
          class="col-auto" 
          width="24" 
          height="24"
          src="https://img.icons8.com/material-outlined/24/visible--v1.png"/>
      </div>

      <button 
        @click="routerTo('Forgot')" 
        class="red bold mt-4 mb-md-0"
        style="background-color: white;"
        :disabled="loading">
          Esqueceu a senha?
      </button>
      
    </section> 

    <Loading 
      v-if="loading" 
      :loading="loading"
      :withLogo="false" 
      class="col-12 mt-5 pb-3 col-md-10"/> 

    <div v-else class="d-flex mt-5 col-12 align-items-center"> 

      <button 
        @click="routerTo('CreateUser')"
        class="col-7 col-lg-5 button-24 bgreen white p-2"
        :disabled="loading">
        Ainda não tem uma conta? <strong>Inscreva-se</strong>
      </button>

      <button
        class="m-0 btn w-50 green raleway"
        style="
          text-decoration: underline !important;
          text-underline-offset: 4px;
          font-size: 18px;"
        @click="doLogin()"
        :disabled="loading">
          Próximo
      </button>
      
    </div>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue"

export default {
  name: "CardLogin",
  components: {
    Loading
  },
  data() {
    return {
      email: null,
      password: null,
      showPass: false,
      loading: false
    };
  },
  methods: {

    ...mapActions('auth', 
      [
        'ActionDoLogin'
    ]),

    routerTo(path) {
      this.$router.push({
        name: path
      });
    },

    async doLogin() {

      localStorage.clear()

      this.loading = true

      await this.ActionDoLogin({
        email: this.email, 
        password: this.password,
        device_id: ""
      })
      .then(() => {

        this.$router.push({
          name: 'Register'
        })

      })
      .catch(() => {})
      .finally(() => {

        this.resetState()
        
      })
    },

    resetState() {

      this.email = ""
      this.password = ""
      this.loading = false

    }

  }
};

</script>
  
<style scoped>

h5, h6 {
  display: inline-block;
  font-family: 'Raleway';
}

p {
  font-size: 14px;
  text-align: justify;
  max-width: 390px;
}

</style>