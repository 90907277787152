import { http } from '@/http';
import flashMessages from '@/utils/flash-messages';

export const ActionDoLogin = async (_, data) => {
  try {
    const response = await http.request({
      url: '/clients/login',
      method: 'POST',
      data,
      json: true,
    });

    sessionStorage.setItem('token', response.data.token);
    sessionStorage.setItem('userId', response.data.client.id);
    return response;
  } catch (error) {
    flashMessages.error('Usuário e/ou senha inválido(s)', '', 2000);
    throw error;
  }
};

export const ActionSendCodeEmail = async (_, data) => {
  try {
    const response = await http.request({
      url: '/clients/reset-password-email',
      method: 'POST',
      data,
      json: true,
    });
    return response;
  } catch (error) {
    flashMessages.warning(
      'Erro ao enviar o e-mail. Favor, tente novamente',
      '',
      2000
    );
    throw error;
  }
};

export const ActionResetPassword = async (_, data) => {
  try {
    const response = await http.request({
      url: '/clients/reset-password',
      method: 'POST',
      data,
      json: true,
    });
    flashMessages.success('Senha alterada com sucesso.', '', 3000);
    return response;
  } catch (error) {
    flashMessages.warning(
      'Erro ao tentar confirmar sua alteração. Tente novamente.',
      '',
      3000
    );
    throw error;
  }
};

export const ActionCreateUser = async (_, data) => {
  try {
    const response = await http.request({
      url: '/clients',
      method: 'POST',
      data,
      json: true,
    });
    sessionStorage.setItem('token', response.token);
    sessionStorage.setItem('userId', response.client?.id);

    return response;
  } catch (error) {
    flashMessages.error('Erro ao criar usuário, tente novamente.', '', 2000);
    throw error;
  }
};
