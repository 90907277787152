<template>
  
  <div v-if="loading" class="screen-center">
    <Loading 
      :loading="loading"
      :withLogo="true"/>
  </div>

  <div v-else class="">

    <button
      class="button-24 logout offset-8 bold white col-auto p-1 ps-3 pe-3"
      @click="routerTo('Login')"
    >
      Sair <img class="ms-2" width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/exit--v1.png" alt="exit--v1"/>
    </button>

    <div class="bg-up-register">

      <img class="register-shape" src="@/assets/register/choose-address-bg.png" alt="">

      <img class="register-location-shape col-4" src="@/assets/register/location.png" alt="">


      <div class="register-text col-8">
        <p class="mb-0">Pedir em</p>
        <p>
          {{ stringAddress ?? 'Endereço não cadastrado' }}
        </p>
      </div>

    </div>

    <div class="register-board">

      <CardRegister 
        v-if="categories" :fullCategories="categories" 
        :fullAddresses="addresses?.data"
        :selectedAddress="stringAddress"
        @updateAddress="updateAddress"
        class="full-screen"
        />

    </div>

  </div>

</template>

<script>

import { mapActions } from "vuex";
import CardRegister from '@/components/register/CardRegister.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: "RegisterView",
  components: {
    CardRegister,
    Loading
  },
  data() {
    return {
      categories: null,
      loading: true,
      addresses: null,
      stringAddress: null
    };
  },
  methods: {

    ...mapActions('register', 
      [
        'ActionGetCategories',
        'ActionGetAddresses'
    ]),

    updateAddress(address) {
      this.stringAddress = address.street + ', ' + address.number + ', ' + address.city ?? 's/n' + ', ' + address.city
    },

    routerTo(path) {
      this.$router.push(path)
    }

  },
  async created() {

    this.loading = true

    try {

      this.categories = await this.ActionGetCategories()
      this.addresses = await this.ActionGetAddresses()
      if (this.addresses && this.addresses.data?.length) { 
        const selectedAddress = this.addresses.data[0] 
        this.stringAddress = selectedAddress.street + ', ' + selectedAddress.number + ', ' + selectedAddress.city ?? 's/n' + ', ' + selectedAddress.city
      }

    } catch (err) {

      console.log({err})

    } finally {

      this.loading = false

    }

  }
};
</script>

<style>

.loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.bg-up-register {
  background-color: var(--green);
  min-height: 30vh;
}

.register-shape {
  position: absolute;
  top: 0;
  left: 0;
  height: 30vh;
  width: 100vw;
}

.register-text {
  position: absolute;
  top: 20vh;
  left: 130px;
  width: 150px !important;
  font-size: 15px;
  color: white;
  font-family: 'Raleway';
}

.register-location-shape {
  position: absolute;
  top: 20vh;
  left: 90px;
  width: 30px !important;
}

.register-board {
  margin: 40px 30px 0 30px;
}

@media (min-width: 768px) {

  .register-text {
    width: 400px !important;
  }

  .bg-up-login {
    min-height: 30vh;
  }

  .login-logo {
    left: 40px;
    width: 10vw;
  }

  .login-text {
    top: 16vh;
    left: 22vw;
  }

  .login-shape {
    width: 30vw;
  }

  p {
    font-size: 18px;
  }

  h2 {
    font-size: 50px !important;
  }

  .full-screen {
    margin-left: 20vw !important;
    padding: 40px 0 40px 0;
    width: 500px;
    min-height: 800px;
    border-left: 3px solid;
    margin-bottom: 40px;
  }

}

</style>
