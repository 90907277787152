<template>
  
  <div>

    <div class="bg-up-address">

      <img class="address-shape" src="@/assets/register/profile-bg.png" alt="">

    </div>

    <CardAddAddress class="full-screen"/>

  </div>

</template>

<script>

import CardAddAddress from '@/components/register/CardAddAddress.vue'

export default {
  name: "AddRe",
  components: {
    CardAddAddress
  },
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  }
};
</script>

<style>

.bg-up-address {
  background-color: var(--green);
  min-height: 20vh;
  width: 100vw;
}

.address-shape {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100vh;
  width: 50vw;
}

h2 {
  font-size: 35px !important;
  font-family: 'Raleway';
}

.up-text {
  font-weight: bold;
}

p {
  font-size: 14px;
}

@media (min-width: 768px) {

  p {
    font-size: 18px;
  }

  h2 {
    font-size: 50px !important;
  }

  .full-screen {
    margin-left: 20vw !important;
    padding: 40px 0 40px 0;
    width: 500px;
    border-left: 3px solid;
  }

}

</style>
