<template>
  
  <div class="">

    <div class="bg-up-create-user">

      <img 
        class="create-user-logo" 
        src="@/assets/login/logo-yellow.png">
      <img 
        class="create-user-shape" 
        src="@/assets/login/create-user-shape.png">

      <div class="create-user-text col-8">
        <p class="mb-0">Antes de continuar o seu chamado</p>
        <h2>Vamos realizar o seu cadastro</h2>
      </div>

    </div>

    <CardCreateUser id="full-screen"/>

  </div>

</template>

<script>

import CardCreateUser from '@/components/auth/CardCreateUser.vue';

export default {

  name: "CreateUserView",

  components: {
    CardCreateUser
  },

  created () {
    sessionStorage.clear()
  }

};

</script>

<style scoped>

.bg-up-create-user {
  background-color: var(--green);
  min-height: 35vh;
}

.create-user-logo {
  position: absolute;
  top: 35px;
  width: 80px;
  left: 30px;
}

.create-user-shape {
  position: absolute;
  top: 0;
  width: 180px;
  right: 0;
}

.create-user-text {
  position: absolute;
  top: 20vh;
  left: 35px;
  color: white;
  font-family: 'Raleway';
}

@media (max-width: 400px) {

  .create-user-text {
    font-size: 20px !important;
  }

  p {
    font-size: 10px;
  }

  h2 {
    font-size: 30px !important;
  }

}


@media (min-width: 768px) {

  .bg-up-create-user {
    min-height: 25vh;
  }

  .create-user-logo {
    left: 40px;
    width: 8vw;
  }

  .create-user-shape {
    width: 25vw;
  }

  .create-user-text {
    top: 10vh;
    left: 20vw
  }

  #full-screen {
    margin-left: 20vw !important;
    padding: 40px 0 40px 0;
    width: 500px;
    border-left: 3px solid;
    max-height: 830px !important;
  }

}

</style>
