import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

http.interceptors.request.use((req) => {
  req.headers = {
    ...req.headers,
    Authorization: 'Bearer ' + sessionStorage.getItem('token'),
  };
  return req;
});

http.interceptors.response.use((resp) => {
  return resp.data;
});

export default {
  http,
};
